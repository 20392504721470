/*******************************
         Site Overrides
*******************************/

.ui.hidden.section.divider {
  margin-bottom: 1rem;
  margin-top:    1rem;
}

.ui.hidden.computer.only.section.divider {
  @media all and (max-width: @largestTabletScreen) {
    display: none;
  }
}

.ui.hidden.tablet.divider {
  @media all and (min-width: @tabletBreakpoint) {
    display: none;
  }
}

.fc.double.ui.section.divider {
  margin-bottom: 0;

  @media all and (min-width: @tabletBreakpoint) {
    margin: 35px auto;
  }
}

.ui.divider:not(.vertical):not(.horizontal) {
  border-top-color: @lightGrey;
}
