@import (multiple) "~fomantic-ui-less/themes/default/globals/site.variables";
@import (multiple) "../site/globals/site.variables";

.gmap {
  background-color: azure;
  height:           50vh;
  min-height:       300px;
}

@media all and (max-width: @largestMobileScreen) {
  .row > .column.gmap-container-mobile {
    position:   fixed !important;
    top:        70px;
    bottom:     0;
    background: rgba(255, 255, 255, 0.9);
    z-index:    1;
    left:       0;
    text-align: right;
  }

  .row > .column.gmap-container-mobile .button {
    margin: 0 1em 1em 1em;
  }

  .row > .column.gmap-container-mobile.hidden {
    display: none !important;
  }
}

@media all and (min-width: @tabletBreakpoint) {
  .row > .column.gmap-container-mobile .button {
    display: none;
  }
}

.row > .column.gmap-container-mobile .button {
  margin: 0 1em 1em 1em;
}


// Google Map Styles
.firstHeading {
  font-size: @relativeLarge;
}
