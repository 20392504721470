@import (multiple) "~fomantic-ui-less/themes/default/globals/site.variables";
@import (multiple) "../site/globals/site.variables";

.ui.logo {
  width:  200px;
  height: 52px;

  @media only screen and (max-width: @largestTabletScreen) {
    width: 110px;
  }

}
