/*******************************
         Site Overrides
*******************************/

.ui.inverted.main.menu {
  &[class*="top fixed"] {
    background-color: @invertedBackground;
    z-index:          1000;
  }

  a.logo.item {
    background: none;
  }
}

.ui.main.menu {
  a.item {
    text-transform: uppercase;

    &.primary {
      color: @primaryColor !important;

      &:hover {
        color: lighten(@primaryColor, 20%) !important;
      }
    }
  }

  &.sidebar {
    .logo {
      background: @darkGrey;
    }
  }
}

.hidden.main.menu {
  display: none;
}

.main.menu .toc.item {
  display: none;
}

@media only screen and (max-width: @largestTabletScreen) {
  .secondary.main.menu .right.menu .item {
    display: none;

    &.always.visible {
      display: block;
    }
  }

  .main.menu > .item,
  .secondary.menu > .item,
  .secondary.menu .right.menu .item {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .secondary.menu .right.menu .item {
    display:    block;
    text-align: right;
  }

  .main.menu:not(.sidebar) .toc.item {
    display: block;
  }

  .ui.inverted.main.menu.default {
    a.logo.item {
      padding-left: 0;
    }
  }

}

.ui.pagination.menu {
  background-color: transparent;
  border:           none;
  box-shadow:       none;

  > .item {
    font-weight: bold;
    color:       @grey;

    &.primary {
      color: @primaryColor;
    }
  }
}

.ui.text.menu {
  margin-top: 0;
  margin-bottom: 5px;

  .item {
    color: @textColor;

    + .item {
      margin-left: 20px;

      &:before {
        content: "";
        background-color: @lightGrey;
        display: inline-block!important;
        margin-right: 10px;
        pointer-events: none;
        position: absolute;
        left: -10px;
        right: auto;
      }
    }
  }
}
