/*******************************
         Site Overrides
*******************************/

.ui.label {
  &.darkblue {
    background-color: @darkBlue;
    border-color: @darkBlue;
    color: @white;
  }
  &.uppercase {
    text-transform: uppercase;
  }
}
