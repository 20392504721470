@import (multiple) "~fomantic-ui-less/themes/default/globals/site.variables";
@import (multiple) "../site/globals/site.variables";

.scrollable {
  overflow: hidden;

  .scroll {
    flex-wrap:   nowrap;
    white-space: nowrap;

    > .field {
      display: inline;
    }
  }

  .scroll-button {
    cursor:      pointer;
    transition:  opacity 0.5s;
    display:     none;
    position:    absolute;
    line-height: 30px;
    width:       50px;
    height:      30px;
    background:  linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
    z-index:     1;
    opacity:     0.8;

    &:hover, &:active, &:focus {
      opacity: 1;
    }

    &.before {
      left: 0;
    }

    &.after {
      right:      0;
      text-align: right;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
    }

    @media all and (max-width: @largestMobileScreen) {
      display: block;
    }
  }
}
