@import (multiple) "~fomantic-ui-less/themes/default/globals/site.variables";
@import (multiple) "../site/globals/site.variables";

.fc.partners {
  @media screen and (max-width: @largestMobileScreen) {
    overflow:           auto;
    white-space:        nowrap;
    -ms-overflow-style: none;
    scrollbar-width:    none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
