/*******************************
         Site Overrides
*******************************/

.ui.grid {
  &[class*="mobile scrollable"] {
    @media all and (max-width: @largestMobileScreen) {
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      flex-wrap: nowrap;
      overflow: auto;

      >.column {
        min-width: 300px;
        margin-right: 1em !important;
      }
    }
  }
}

.ui.grid > .column:not(.row) {
  &[class*="no spacing"] {
    padding: 0;

    @media only screen and (max-width: @largestMobileScreen) {
      margin: -1em !important;
      width:  calc(100% + 2em) !important;
    }
  }
}

.flex {
  display: flex;

  &.f-column {
    flex-direction: column;
    height:         100%;
  }

  > .flex-item {
    flex: 1 0 auto;

    &.fi-stretch {
      justify-self: stretch;
    }

    &.fi-flat {
      flex: 0 1 auto;
    }
  }
}
