/*******************************
         Site Overrides
*******************************/

.custom-form.ui.form .fields {
  margin-bottom: 0;
}

.ui.form {
  .fields {
    &[class*="bottom aligned"] {
      align-content: flex-end;
    }

    &.spacy {
      margin-top: 1em;

      &:not(.inline) {
        .field {
          margin-bottom: 1em;
        }
      }
    }
  }

  .fieldset {
    border: 0px;
    margin: 0px;
    padding: 0px;
  }

  .legend {
    display: inline;
    float: left;
    margin-right: 1em;
  }

  .field {
    &[class*="middle aligned"] {
      align-self: center;
    }

    @media all and (max-width: @largestMobileScreen) {
      &.mobile-space-top {
        margin-top: 1em;

        &.double-space {
          margin-top: 2em;
        }

        label {
          display: none;
        }
      }
    }
  }

  textarea,
  textarea:active,
  textarea:focus {
    border-radius: 15px;
  }
}

// Display on different devices
.ui.form > .fields,
.ui.form .container > .fields {

  // only
  &[class*="desktop only"],
  [class*="desktop only"].field {
    @media all and (max-width: @largestTabletScreen) {
      display: none;
    }
  }

  &[class*="tablet only"],
  [class*="tablet only"].field {
    @media all and (max-width: @largestMobileScreen) {
      display: none;
    }
  }

  &[class*="mobile only"],
  [class*="mobile only"].field {
    @media all and (min-width: @tabletBreakpoint) {
      display: none;
    }
  }

  .field {
    label[class*="desktop only"] {
      @media all and (max-width: @largestTabletScreen) {
        display: none;
      }
    }

  }

}
