@import (multiple) "~fomantic-ui-less/themes/default/globals/site.variables";
@import (multiple) "../site/globals/site.variables";

.fc.rule {
  &.horizontal {
    border-top: 1px solid @lightGrey;
    height:     1px;
    margin:     1em auto;

    &.fluid {
      width: 100%;
    }
  }
}
