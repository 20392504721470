@import (multiple) "~fomantic-ui-less/themes/default/globals/site.variables";
@import (multiple) "../site/globals/site.variables";
@import "~lightbox2/src/css/lightbox.css";

@countSize: 75px;
@imageSpacing: 2px;

.gallery {
  flex-direction: column;
  display:        flex;
  margin-bottom:  1em;
  min-height: 300px;

  @media screen and (min-width: @tabletBreakpoint) {
    flex-direction: row;
  }

  .ui.image {
    margin: 0;
    padding: @imageSpacing;
  }

  .cover,
  .thumbs,
  .item {
    flex: 0 0 50%;
  }

  @media screen and (min-width: @tabletBreakpoint) {
    .cover {
      .ui.image {
        padding: @imageSpacing 0;
      }
    }
  }

  .item.hidden {
    display: none;
  }

  .thumbs {
    display:   flex;
    flex-wrap: wrap;
    flex:      0 0 50%;
    position:  relative;
  }

  .count {
    align-items:      center;
    background-color: @pageBackground;
    border-radius:    50%;
    bottom:           calc(~"50% - " @countSize / 2);
    color:            @textColor;
    display:          flex;
    height:           @countSize;
    padding:          10px;
    position:         absolute;
    right:            calc(~"50% - " @countSize / 2);
    text-align:       center;
    transition:       all .3s;
    width:            @countSize;

    @media screen and (min-width: @tabletBreakpoint) {
      bottom: calc(~"25% - " @countSize / 2);
      right:  calc(~"25% - " @countSize / 2);
    }

    &:hover {
      background-color: @textColor;
      color:            @pageBackground;
    }
  }
}
