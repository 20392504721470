/*******************************
         Site Overrides
*******************************/

.uppercase {
  text-transform: uppercase;
}

html.handicap {
  &-font {
    font-size: 20px;
  }

  &-contrast {
    background-color: @black !important;
    color: @white !important;

    a {
      color: @yellow !important;

      &:hover {
        color: @white !important;
      }
    }

    .ui.menu .ui.dropdown {
      .text {
        color: @yellow !important;
      }

      .menu {
        background-color: @black !important;

        & > .item {
          color: @yellow !important;
        }
      }
    }

    .ui.vertical.menu {
      background-color: @darkGrey;
    }

    .ui.card {
      background-color: @black;
      color: @white;

      .meta {
        color: @white;
      }

      & > .content {
        & > .header {
          color: @white;
        }

        & > .description {
          color: @white;
        }
      }
    }

    .ui.form {
      .field > label {
        color: @white;
      }
    }

    .ui.selection.dropdown {
      background-color: @black;
      color: #ccc;

      &:hover {
        border-color: @white;
      }

      input {
        color: @white;
      }
    }

    .ui.form.custom-form {
      background-color: @black;

      .ui.checkbox {
        label {
          color: @white;
        }
      }
    }

    .ui.input {
      background-color: @black;
      color: @white;

      .icon {
        color: #ccc;
      }

      input {
        background-color: @black !important;
        color: @white;

        &:focus,
        &:hover {
          color: @white;
          border-color: @white;
        }
      }
    }

    .ui.vertical.masthead.segment {
      background-image: linear-gradient(-228deg, rgba(0, 0, 0, .6) 0%, #000 100%), url("/images/header/header2.jpg");
      background-blend-mode: luminosity;
    }

    body.pushable > .pusher {
      background: @darkGrey;
      color: @white;

      .ui.header,
      .ui.header .sub.header {
        color: @white;
      }
    }

    .ui.cards a.card:hover, .ui.link.cards .card:not(.icon):hover, a.ui.card:hover, .ui.link.card:hover {
      background-color: @black;
    }

    .ui.card.fc .image.background .overlay {
      background-image: linear-gradient(to bottom, rgba(@black, .4) 0%, @black 95%);
    }

    .ui.card.fc > .content > .text.extra.content {
      color: @white;
    }

    .ui.primary.button {
      color: @black !important;
    }

    .ui.secondary.button {
      border: 1px solid @darkGrey;
      background-color: @darkGrey;
      color: @yellow;

      &:hover {
        color: @white;
        border: 1px solid @white;
      }
    }

    .event .content {
      color: @white;
      .summary,
      .meta,
      .extra.text {
        color: @white !important;
      }
    }

    .gallery .count {
      background-color: @black;

      &:hover {
        transform: scale(1.2);
      }
    }

    .blog .card .description:after {
      background: linear-gradient(to top, @black 0%, @black 20%, rgba(@black, 0) 100%)
    }

    .blog-intro {
      color: @white;
    }
  }
}
