@import (multiple) "~fomantic-ui-less/themes/default/globals/site.variables";
@import (multiple) "../site/globals/site.variables";

.blog {
  margin-bottom: 2em !important;
  min-height: 460px;

  .card {
    .image {
      object-fit: cover;
      height: 300px;
      width: auto;
    }

    .description {
      max-height: @lineHeight * 5;
      overflow: hidden;
      position: relative;

      &:after {
        background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
        bottom: 0;
        content: "";
        display: block;
        height: @lineHeight * 2;
        position: absolute;
        width: 100%;
      }
    }
  }

  &-cover {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  &-intro {
    color: @grey;
    font-size: 1.5em;
    margin-bottom: 2em;
  }

  &-empty {
    width: 100%;
    margin-top: 3em;
    margin-bottom: 3em;
  }
}
