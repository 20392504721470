/*******************************
         Site Overrides
*******************************/
@import (multiple) "~fomantic-ui-less/themes/default/globals/site.variables";

.ui.header {
  &[class*="no spacing"] {
    margin:      auto;
    line-height: 1.5;
  }
}

// Sizing
.ui.header {
  &[class*="desktop large"] {
    @media all and (min-width: @tabletBreakpoint) {
      font-size: @largeFontSize;
    }
  }
  &[class*="mobile medium"] {
    @media all and (max-width: @largestMobileScreen) {
      font-size: @mediumFontSize;
    }
  }
}
//.ui.huge.header {
//  min-height: 1em;
//  font-size: @hugeFontSize;
//}
//.ui.small.header {
//  font-size: @smallFontSize;
//}
//.ui.tiny.header {
//  font-size: @tinyFontSize;
//}
