/*******************************
    User Variable Overrides
*******************************/
.ui.feed {
  > .event {
    > .label {
      &[class*="fc home"] {
        width: 150px;
      }

      img {
        &.shadowed {
          box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.15);
        }

        &.square {
          height: 150px;
          width:  150px;
        }
      }
    }
  }

  &.fc {
    > .event {
      @media screen and (max-width: @largestMobileScreen) {
        flex-direction: column;
        > .label {
          align-self: center;
        }

        > .content {
          align-self: center;
          margin:     auto;

          .summary, .meta {
            text-align: center;
            display:    block;
          }
        }
      }
    }
  }
}
.scrollable .fc.ui.feed {
  @media screen and (max-width: @largestMobileScreen) {
    margin-right: 2em;
  }
}
