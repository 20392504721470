@import (multiple) "~fomantic-ui-less/themes/default/globals/site.variables";
@import (multiple) "../site/globals/site.variables";

.featured {
  .gradient-image {
    min-height:          369px;
    max-width:           100%;
    width:               100%;
    height:              100%;
    background-position: center center;
    background-size:     cover;

    @media all and (max-width: @largestMobileScreen) {
      &.desktop {
        display: none;
      }
    }

    @media all and (min-width: @tabletBreakpoint) {
      &.mobile {
        display: none;
      }
    }
  }

  .header {
    .ui.header {
      font-weight: normal;
    }
  }

  .column.spaced {
    margin-top:    1em;
    margin-bottom: 1em;
  }

  .content {
    color:      @lightGrey;
    fill:       @lightGrey;
    margin-top: 20px;

    &.extra {
      .button {
        margin-top: 25px;
      }
    }
  }
}
