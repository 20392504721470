/*******************************
         Site Overrides
*******************************/

.ui.button {
  &.shadowed {
    box-shadow: @shadowedBorderBoxShadow @grey;
    @media all and (max-width: @largestMobileScreen) {
      box-shadow: @shadowedBorderBoxShadowMobile @grey;
    }

    &.primary {
      box-shadow: @shadowedBorderBoxShadow @primaryColor;
      @media all and (max-width: @largestMobileScreen) {
        box-shadow: @shadowedBorderBoxShadowMobile @primaryColor;
      }
    }
  }
}
