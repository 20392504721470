/*******************************
         Site Overrides
*******************************/

.ui.vertical.masthead.segment {
  min-height:       50px;
  background-image: linear-gradient(-228deg, rgba(0, 0, 0, 0.00) 0%, #000 100%),
                    repeating-linear-gradient(150deg,
                    rgba(0, 0, 0, 0),
                    rgba(0, 0, 0, 0) 1px,
                    rgba(0, 0, 0, 0.5) 1px,
                    rgba(0, 0, 0, 0.5) 2px),
                    url("/images/header/header2.jpg");
  background-size:  cover;
  padding-bottom:   0;
}

.ui.vertical.segment {
  border-bottom: none;
}
