/*******************************
    User Variable Overrides
*******************************/

.ui.huge.card {
  .content {
    @media all and (min-width: @tabletBreakpoint) {
      padding: 3em;

      .header {
        font-size: 2.5em;
      }
    }
  }

  box-shadow: 0 2px 45px 0 rgba(0, 0, 0, 0.50);
}

.ui.cards > .card > .content > .header:not(.ui),
.ui.card > .content > .header:not(.ui) {
  line-height: 1.4em;
  margin-bottom: 0.4em;
}

.ui.cards {
  &[class*="mobile scrollable"] {
    @media all and (max-width: @largestMobileScreen) {
      display:   flex !important;
      flex-wrap: nowrap;
      overflow:  auto;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      > .card {
        min-width: 250px;
        width:     250px !important;

        &:last-child {
          margin-right: 2em;
        }
      }
    }
  }
}

.ui.card {
  &.fc {
    .image:not(.ui) {
      flex:     0 0 125px;
      overflow: hidden;
    }

    &.tall {
      .image:not(.ui) {
        flex:     0 0 105px;
        overflow: hidden;
      }
    }

    .image.placeholder {
      background:      url("/images/placeholder.png") no-repeat center center;
      background-size: cover;
    }

    .image.background {
      position: relative;

      .overlay {
        position:         absolute;
        bottom:           0;
        left:             0;
        width:            100%;
        padding:          .5em 1em;
        color:            @white;
        text-shadow:      1px 1px 2px #000;
        background-image: linear-gradient(to bottom, rgba(80, 75, 120, 0) 30%, rgba(80, 75, 120, 1) 95%);

        .header {
          font-weight: @headerFontWeight;
          font-size:   @headerFontSize;
          margin-top:  @headerLineHeightOffset;
          padding-bottom: 10px;
        }

        .meta {
          color: @white;
        }
      }
    }

    > .content {
      & > .small.header {
        font-size: @relativeMedium;
      }

      & > .text.extra.content {
        font-weight: @headerFontWeight;
        color:       @headerColor;
      }
    }

    .horizontal-visible {
      display: none;
    }

    &.desktop.horizontal {

      @media all and (min-width: @tabletBreakpoint) {
        .image {
          flex:          0 0 283px;
          height:        200px;
          width:         283px;
          border-radius: @borderRadius 0 0 @borderRadius !important;
        }

        .content {
          border-top: none;
        }

        .horizontal-visible {
          display: block;
        }
      }

      @media all and (max-width: @largestMobileScreen) {
        flex-direction: column;
        min-width:      auto;

        & > .content {
          flex-basis: auto;
        }

        > .image {
          flex:   0 0 150px;
          height: 150px;
          width:  100%;

          > img {
            height:    auto;
            max-width: 100%;
          }
        }
      }
    }
  }

  &.shadowed {
    transition: all 0.3s;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.25);

    &.link:hover {
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.23);
    }
  }

  [class*="extra content"] {
    .item {
      margin: auto @relative4px;
    }
  }

  > .content {
    display:        flex;
    flex-direction: column;

    > .description {
      flex-grow: 1;
    }

    > .extra {
      flex-grow:  0;
      margin-top: 1em;
    }
  }
}
