@import (multiple) "~fomantic-ui-less/themes/default/globals/site.variables";
@import (multiple) "../site/globals/site.variables";

.ui.huge.card > .content {
  > .huge.header {
    margin-top:  0;
    line-height: 1.5em;

    @media all and (max-width: @largestMobileScreen) {
      font-size: 1.6em;
    }
  }

  label {
    margin: 0 0 .9rem;
  }

  .ui.input {
    min-height: 45px;
  }

  .ui.left.icon.input {
    .multiple.search.selection.dropdown.tags {
      .ui.label {
        margin-bottom: .4rem;
        margin-top:    .4rem;
      }

      .search,
      .text {
        line-height: 1.6em;
      }

      .remove.icon {
        top: 1.2em;
      }

      .icon.input {
        line-height: 1.5em;
      }
    }
  }
}
