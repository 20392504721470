/*******************************
         Site Overrides
*******************************/

@media only screen and (min-width: @largeMonitorBreakpoint) {
  .ui.super.wide.container {
    max-width: 1400px !important;
    width:     auto !important;
  }
}

@media only screen and (max-width: @largestMobileScreen) {
  .ui.grid.container {
    &[class*="no margin"] {
      margin-left:  0 !important;
      margin-right: 0 !important;
    }
  }
}
