.ui.quote {
  margin-left: auto;
  width:       143px;

  .part {
    background: url("/svg/quote.svg") no-repeat;
    min-height: 15px;

    &.content {
      background:   none;
      border-left:  1px solid #9c9c9c;
      border-right: 1px solid #9c9c9c;
      padding:      0 1em;

      .top {
        font-style: italic;
      }
    }

    &.tail {
      background-position: bottom;
      height:              48px;
    }
  }
}
