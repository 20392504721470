/*******************************
         Site Overrides
*******************************/
.ui.left.icon.input {
  .multiple.search.selection.dropdown.tags {
    .icon.input {
      position:    absolute;
      opacity:     0.5;
      height:      100%;
      bottom:      0;
      left:        0;
      top:         0;
      line-height: 1.3em;
      margin:      0.78em;
    }
  }
}
