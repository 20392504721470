@import (multiple) "~fomantic-ui-less/themes/default/globals/site.variables";
@import (multiple) "../site/globals/site.variables";

.ui.link {
  color: @linkColor;

  &.primary {
    color: @primaryColor;
  }

  &.secondary {
    color: @secondaryColor;
  }
}
