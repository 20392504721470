.sprite {
  height:         auto;
  width:          30px;
  vertical-align: middle;
  margin-right: 10px;
}

.sprite-text {
  line-height: 30px;
}

.sprite-list {
  display: inline-block;
  padding: 0;
  vertical-align: top;

  li {
    display: block;
  }
}
