@import (multiple) "~fomantic-ui-less/themes/default/globals/site.variables";
@import (multiple) "../site/globals/site.variables";

.handicap {
}

.sidebar.main.menu {
  .handicap {
    display: inline-block;
  }
}
